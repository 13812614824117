@tailwind base;
@tailwind components;
@tailwind utilities;

h1, h2, h3, h4, h5, h6, p, span, li, a {
  @apply dark:text-gray-100
}

h1 {
  @apply text-2xl leading-10
}

h2 {
  @apply text-xl leading-10
}

h2 {
  @apply text-lg leading-10
}

p {
  @apply leading-relaxed
}
